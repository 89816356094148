var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "govuk-warning-text" }, [
    _c(
      "span",
      {
        staticClass: "govuk-warning-text__icon",
        attrs: { "aria-hidden": "true" }
      },
      [_vm._v("!")]
    ),
    _c(
      "strong",
      { staticClass: "govuk-warning-text__text" },
      [
        _c("span", { staticClass: "govuk-warning-text__assistive" }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }